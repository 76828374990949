import React from 'react';
import { IFlow, IOption } from '..';

interface IOptions {
  options: IOption[];
  handleSelectFlow(flow: IFlow, optionId?: number): void;
}

const Options: React.FC<IOptions> = ({ options, handleSelectFlow }) => {
  return (
    <>
      {options.map((option) => (
        <div className="pt-2 ps-5">
          <div className="d-flex align-items-center">
            <span className="option-selector" />
            <button
              type="button"
              className="flow"
              onClick={() => handleSelectFlow(option.flow, option.id)}
            >
              {option.flow.name}
            </button>
          </div>
          {option.flow.options && option.flow.options.length > 0 && (
            <Options
              options={option.flow.options}
              handleSelectFlow={handleSelectFlow}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default Options;
