import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 23px;
    background: #fff;
    border: none;
    position: relative;

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .option {
      span {
        width: 45px;
      }

      .input {
        width: calc(100% - 45px);
      }
    }

    .input-radio {
      label + label {
        margin-left: 0;
      }
    }
  }
`;
