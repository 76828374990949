import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Container } from './styles';
import Textarea from '../Textarea';

interface IInputEditor extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  uploadUrl?: string;
  hasError?(hasError: boolean): void;
}

const InputEditor: React.FC<IInputEditor> = ({
  name,
  uploadUrl = 'http://localhost:3333/upload-files',
  className,
  onChange,
  onFocus,
  onBlur,
  value: valueData,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (valueData) {
      setValue(valueData as string);
    }
  }, [valueData]);

  const handleEditorChange = useCallback(
    (event, editor) => {
      setValue(editor.getData());

      if (onChange) {
        onChange(editor.getData());
      }
    },
    [onChange]
  );

  return (
    <Container className={className}>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          ckfinder: {
            uploadUrl,
          },
        }}
        onChange={handleEditorChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <Textarea name={name} value={value} className="d-none" />
    </Container>
  );
};

export default InputEditor;
