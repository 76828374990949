import { darken } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  .btn-blue {
    background-color: #0075ff;
    color: #fff;
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.1, '#0075FF')};
    }
  }

  .box {
    border-radius: 23px;
    background: #fff;

    .flow {
      border: 1px solid #202020;
      border-radius: 10px;
      padding: 10px 16px;
      width: 100%;
      background-color: #fff;
      position: relative;
      z-index: 1;
    }

    .option-selector {
      position: relative;
      display: block;
      height: 24px;

      ::before {
        content: '';
        position: absolute;
        display: block;
        width: 20px;
        height: 32px;
        border-left: 1px solid #202020;
        border-bottom: 1px solid #202020;
        top: -20px;
        right: 0;
      }

      ::after {
        content: '';
        position: absolute;
        display: block;
        width: 20px;
        height: 32px;
        border-left: 1px solid #202020;
        top: -43px;
        right: 0;
      }
    }

    .btn-add {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .table-messages {
      > div {
        .content {
          overflow: auto;

          .tb-header,
          .tb-body {
            min-width: 731px;
            width: 100%;

            .tb-row {
              width: 100%;

              > button:nth-child(1),
              > div:nth-child(1) {
                width: 50px;
              }

              > button:nth-child(2),
              > div:nth-child(2) {
                width: 250px;
              }

              > button:nth-child(3),
              > div:nth-child(3) {
                width: 250px;
              }

              > button:nth-child(4),
              > div:nth-child(4) {
                display: flex;
                align-items: center !important;
                justify-content: center !important;

                > svg {
                  display: none;
                }
              }

              > button:nth-child(5),
              > div:nth-child(5) {
                width: 90px;
              }
            }
          }
        }
      }
    }

    .input {
      background: #f9f9f9;
      border-radius: 50px;
      border: 1px solid #e4e4e4;
      padding: 5px 15px;
      width: 400px;
      display: flex;
      align-items: center;
      height: 46px;
      color: #777777;

      input {
        width: calc(100% - 32px);

        ::placeholder {
          color: #a3a3a3;
        }
      }
    }

    .btn-filter {
      border-radius: 50%;
      border: 1px solid #e4e4e4;
      background: #f9f9f9;
      padding: 10px;
    }

    .box-gray {
      border-radius: 16px;
      background: #f9f9f9;
    }

    + .box {
      margin-top: 20px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 23px;
    background: #fff;
    border: none;
    position: relative;

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
`;
