import styled, { css } from 'styled-components';

interface IPreview {
  src: string;
}

interface ICharacters {
  qty: number;
  min: number;
  max: number;
}

export const Container = styled.div`
  .btn-delete {
    color: #ff1a50;
  }

  .box {
    border-radius: 8px;
    background: #fff;

    .cursor-pointer {
      cursor: pointer;
    }

    .plus-icon {
      background-color: #5a5a5a;
      border: 2px solid #9c9c9c;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    hr {
      background: #e7e7e7;
    }

    .input {
      background: #f9f9f9;
    }

    .title {
      height: unset;

      textarea {
        color: #202020;
        font-size: 32px;
        font-weight: 600;
        width: 100%;
        height: 58px;
        transition-duration: 0.3s;
      }
    }

    .description {
      height: unset;

      textarea {
        width: 100%;
        color: #707070;
        transition-duration: 0.3s;
      }
    }
  }
`;

export const Preview = styled.div<IPreview>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  border-radius: 10px;
`;

export const Characters = styled.p<ICharacters>`
  transition-duration: 0.3s;
  border-radius: 100px;

  ${(props) => {
    const { qty, min, max } = props;
    if (qty > 0 && qty < min) {
      return css`
        color: #ff1a50;
        background: #fde6e8;
      `;
    }

    if (qty >= min && qty <= max) {
      return css`
        color: #00c968;
        background: #dbf5e9;
      `;
    }

    if (qty > max) {
      return css`
        color: #ff1a50;
        background: #fde6e8;
      `;
    }

    return css`
      color: #707070;
      background: #f1f0f0;
    `;
  }};
`;
