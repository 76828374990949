import styled from 'styled-components';

export const Container = styled.div`
  .ck-toolbar {
    background: #d8d8d8 !important;
    border-top-left-radius: 11px !important;
    border-top-right-radius: 11px !important;
    border-color: #d8d8d8 !important;
    padding: 1rem !important;
  }

  .ck-content {
    border: 1px solid #d8d8d8;
    background: #f8f8f8;
    box-shadow: none !important;
    min-height: 282px;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding: 0 2rem !important;
  }

  .ck-focused {
    border: 1px solid #202020 !important;
  }
`;
