import styled from 'styled-components';

import bannerDashboard from '~/assets/banners/banner-dashboard.svg';

export const Container = styled.div`
  .box {
    border-radius: 13px;
    border: 1px solid #b0b0b0;
    background: #f2f2f2;

    .detail {
      width: 20.35px;
      height: 20.35px;
      border-radius: 8px;
    }

    .detail.bg-yellow {
      background: #f6c154;
    }

    .detail.bg-brown {
      background: #995c19;
    }

    .detail.bg-orange {
      background: #eb7b00;
    }
  }
`;

export const Banner = styled.div`
  background-color: #f9f9f9;
  background-image: url(${bannerDashboard});
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;

  p {
    font-size: 18px;
    color: #a2a2a2;
  }
`;
