import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';
import InfiniteScroll from '~/components/InfiniteScroll';

interface IOptions {
  show: boolean;
}

interface IBallonBox {
  me: boolean;
}

export const Container = styled.div``;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const Content = styled.div`
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #efefef;

  .no-chat {
    border-radius: 20px;
    background-color: #f9f9f9;
    border: 1px solid #efefef;
    height: calc(100vh - 225px);
  }
`;

export const Notifications = styled.div`
  h2 {
    color: #202020;
  }
  p {
    color: #707070;
  }

  hr {
    border-top: 1px solid #606060;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .height-notifications {
    height: calc(100vh - 250px);
  }

  .notifications-hover {
    background-color: #f7f7f7;
    transition-duration: 0.3s;
    border-radius: 16px;

    :hover {
      background-color: ${darken(0.03, '#f7f7f7')};
      text-decoration: none;
    }
  }

  .notification {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #be7e38;
  }
`;

export const ChatArea = styled.div`
  .avatar-profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  form {
    border-radius: 20px;
    background-color: #f7f7f7;

    .bodyChat,
    .input-box {
      border-radius: 20px;
      background-color: #fff;
    }

    .bodyChat {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    .input-box {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      .textarea {
        textarea {
          resize: none;
        }
      }

      > div {
        border-radius: 20px;
        background-color: #f7f7f7;
      }
    }

    .reply {
      background-color: #e4e4e455;
      border-radius: 10px;
      position: relative;
      padding: 0 16px 0 32px;
      transition-duration: 0.3s;

      .detail {
        width: 6px;
        height: 100%;
        position: absolute;
        background: #e4e4e4;
        top: 0;
        left: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .btn-close-reply {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .reply.active {
      padding: 16px 16px 16px 32px;
    }
  }
`;

export const ChatText = styled(InfiniteScroll)`
  margin-top: 1rem;
  overflow-y: auto;
  height: calc(100vh - 400px);

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .bg-dark-gray {
    color: #fff;
    font-weight: 500;
    border-radius: 10px 10px 0 10px;
    background-color: #8a8a8a;
  }

  .bg-light-gray {
    color: #3d3f41;
    font-weight: 500;
    border-radius: 10px 10px 10px 0;
    background-color: #e4e4e4;
  }

  .date-message {
    font-weight: 500;
    color: #b0b6c3;
    font-size: 10px;
  }

  .tail-dark-gray {
    position: relative;
    top: 8px;
    z-index: 100;
    display: block;
    width: 16px;
    height: 13px;
    left: 28px;
  }

  .tail-light-gray {
    position: relative;
    top: 9px;
    z-index: 100;
    display: block;
    width: 16px;
    height: 13px;
    left: -30px;
  }

  .btn-approve,
  .btn-delete,
  .btn-reply {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .btn-approve {
    background-color: #35dc6c;

    :hover {
      background-color: ${darken(0.1, '#35dc6c')};
    }
  }

  .btn-delete {
    background-color: #dc3545;

    :hover {
      background-color: ${darken(0.1, '#dc3545')};
    }
  }

  .btn-reply {
    background-color: #6c757d;

    :hover {
      background-color: ${darken(0.1, '#6c757d')};
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 4px;
  }
`;

export const Modal = styled(ModalComponent)``;

export const Options = styled.div<IOptions>`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  position: absolute;
  width: 250px;
  top: 35px;
  right: -17px;
  z-index: 100;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  button:first-child {
    ::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: #fff;
      border-top: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;
      transform: rotateZ(45deg);
      top: -9px;
      right: 20px;
      transition-duration: 0.3s;
    }
  }

  button {
    border-radius: 16px;
    background-color: transparent;
    transition-duration: 0.3s;
    width: 100%;

    :hover {
      background-color: ${darken(0.05, '#fff')};

      ::before {
        background-color: ${darken(0.05, '#fff')};
      }
    }

    + button {
      border-top: 1px solid #e0e0e0;
    }
  }
`;

export const BallonBox = styled.div<IBallonBox>`
  justify-content: ${(props) => (props.me ? 'flex-end' : 'flex-start')};

  > div {
    > div {
      display: flex;
      flex-direction: column;
      align-items: ${(props) => (props.me ? 'flex-end' : 'flex-start')};

      .ballon {
        color: ${(props) => (props.me ? '#fff' : '#3d3f41')};
        background-color: ${(props) => (props.me ? '#be7e38' : '#e4e4e4')};
        border-radius: ${(props) =>
          props.me ? '10px 10px 0 10px' : '10px 10px 10px 0'};
        max-width: 300px;
      }
    }
  }
`;
