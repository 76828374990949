/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { IoCloseOutline } from 'react-icons/io5';
import { RxPlus, RxTrash } from 'react-icons/rx';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { Modal } from './styles';
import Input from '~/components/Input';
import Select, { IOption as IOptionSelect } from '~/components/Select';
import Textarea from '~/components/Textarea';
import { IFlow } from '..';
import InputCheckbox, {
  IOption as IOptionCheckbox,
} from '~/components/InputCheckbox';
import api from '~/services/api';

interface IOption {
  id: number;
  content: string;
  status: 'new' | 'old';
  flow: IFlow;
}

interface IFlowModal {
  flow: IFlow;
  show: boolean;
  onHide: () => void;
  onSave: (flow: IFlow) => void;
}

interface IUserType {
  id: number;
  type: string;
}

const FlowModal: React.FC<IFlowModal> = ({ flow, show, onHide, onSave }) => {
  const formRef = useRef<FormHandles>(null);
  const [options, setOptions] = useState<IOption[]>([]);
  const [action, setAction] = useState('');
  const [sectors, setSectors] = useState<IOptionSelect[]>([]);

  useEffect(() => {
    if (flow.options.length > 0) {
      setOptions(flow.options);
    }
    setAction(flow.action);
  }, [flow.action, flow.options]);

  useEffect(() => {
    api.get<IUserType[]>('users-types').then((response) => {
      const data = response.data.map<IOptionSelect>((userType) => ({
        id: userType.id,
        value: userType.type,
        selected: flow.user_type_id === userType.id,
      }));
      setSectors(data);
    });
  }, [flow.user_type_id]);

  const handleClickAddOption = useCallback(() => {
    setOptions((oldState) => [
      ...oldState,
      {
        id: new Date().getTime(),
        content: '',
        status: 'new',
        flow: {
          id: new Date().getTime(),
          name: '',
          message: '',
          status: 'new',
          options: [],
          action: 'options',
        },
      },
    ]);
  }, []);

  const handleClickRemoveOption = useCallback((option_id: number) => {
    setOptions((oldState) =>
      oldState.filter((option) => option.id !== option_id)
    );
  }, []);

  const handleChangeContent = useCallback(
    (e, optionIndex) => {
      const newOptions = options.slice();
      newOptions[optionIndex].content = e.target.value;
      setOptions(newOptions);
    },
    [options]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          message: Yup.string().required('A mensagem é obrigatória'),
          ...options.reduce((previous, option) => {
            const newPrevious = previous;
            newPrevious[`content_${option.id}`] = Yup.string().required(
              'O valor da opção é obrigatório'
            );

            return newPrevious;
          }, {} as { [key: string]: Yup.StringSchema<string | undefined, Record<string, any>, string | undefined> }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { message, sector } = data;

        const formData = {
          ...flow,
          message,
          options: options.map((option, index) => ({
            ...option,
            selector: index + 1,
            flow: {
              ...option.flow,
              id: option.flow.id,
              name: option.content,
            },
          })),
          user_type_id: sector,
          action,
        } as IFlow;

        onSave(formData);

        // setShow(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [action, flow, onSave, options]
  );

  const handleChangeAction = useCallback((optionsData: IOptionCheckbox[]) => {
    const optionSelected = optionsData.find((option) => option.selected);
    if (optionSelected) {
      setAction(optionSelected.value as string);
    } else {
      setAction('options');
    }
  }, []);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Form ref={formRef} onSubmit={handleSubmit} className="p-3">
        <Modal.Header className="border-0">
          <h3>{flow.name}</h3>
          <button
            type="button"
            onClick={onHide}
            className="border-0 bg-transparent close-btn"
          >
            <IoCloseOutline size={38} color="#202020" />
          </button>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
          <label className="w-100 mb-3">
            <span className="d-block mb-2 fw-medium">Mensagem</span>
            <Textarea
              name="message"
              placeholder="Digite aqui"
              defaultValue={flow.message}
            />
          </label>
          {action && (
            <div className="d-flex flex-column w-100">
              <label>Qual ação voce deseja?</label>
              <InputCheckbox
                type="radio"
                name="action"
                onChange={handleChangeAction}
                options={[
                  {
                    label: 'Opções',
                    value: 'options',
                    selected: action === 'options',
                  },
                  {
                    label: 'Chat livre',
                    value: 'freedom',
                    selected: action === 'freedom',
                  },
                ]}
                className="input-radio flex-column"
              />
            </div>
          )}
          {action === 'options' && (
            <div className="w-100 mt-3">
              {options.map((option, index) => (
                <div
                  key={option.id}
                  className="d-flex align-items-center w-100 mb-3"
                >
                  <label className="w-100 d-flex align-items-center option">
                    <span className="d-block fw-medium">{index + 1} - </span>
                    <div className="input">
                      <Input
                        name={`content_${option.id}`}
                        placeholder="Digite aqui"
                        onChange={(e) => handleChangeContent(e, index)}
                        defaultValue={
                          (flow.options.length > 0 &&
                            flow.options[index]?.content) ||
                          undefined
                        }
                      />
                    </div>
                  </label>
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    onClick={() => handleClickRemoveOption(option.id)}
                  >
                    <RxTrash size={24} color="#FF003D" />
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary w-100 d-flex align-items-center justify-content-center"
                onClick={handleClickAddOption}
              >
                <RxPlus size={24} color="#fff" className="me-2" />
                Adicionar nova opção
              </button>
            </div>
          )}
          {action === 'freedom' && (
            <div className="w-100 mt-3">
              <label className="w-100 d-flex align-items-center option">
                <span className="w-100 d-block fw-medium">
                  Setor que receberá a mensagem
                </span>
              </label>
              <Select
                name="sector"
                placeholder="Digite aqui"
                options={sectors}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FlowModal;
