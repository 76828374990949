import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';

import Logo from '~/assets/logos/Logo';
import signinBackground from '~/assets/banners/signin-background.svg';
import { useAuth } from '~/hooks/Auth';

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Insira um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [signIn]
  );

  return (
    <Container>
      <div className="container pt-5 pb-4">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-5">
            <Link
              to={`${process.env.PUBLIC_URL}`}
              className="d-flex d-lg-none align-items-end mb-5"
            >
              <Logo color="#202020" className="me-2" />
              <span className="text-start d-block fs-6 fs-lg-4 fw-light mb-0 logo-name">
                Prime Odontologia
              </span>
            </Link>
            <div className="w-lg-75">
              <p className="h5 fw-light">Bem-vindo!</p>
              <h1>Para entrar, insira suas credências</h1>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit} className="mt-5">
              <label className="d-block w-100 mb-3">
                <span className="fw-medium mb-2 d-block">E-mail</span>
                <Input
                  type="email"
                  name="email"
                  placeholder="Insira seu e-mail"
                />
              </label>
              <label className="d-block w-100 mb-3">
                <span className="fw-medium mb-2 d-block">Senha</span>
                <Input
                  type="password"
                  name="password"
                  placeholder="Digite sua senha"
                />
              </label>
              <div className="d-flex justify-content-end">
                <Link
                  to={`${process.env.PUBLIC_URL}/esqueci-minha-senha`}
                  className="text-primary fw-semibold"
                >
                  Esqueci minha senha
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary rounded-pill w-100 py-2 mt-5"
              >
                Entrar
              </button>
            </Form>
          </div>
        </div>
      </div>
      <img src={signinBackground} alt="" className="banner d-none d-lg-block" />
    </Container>
  );
};

export default SignIn;
