import styled from 'styled-components';

interface IMenu {
  active: boolean;
}

interface IAvatar {
  src: string;
}

export const WhiteSpace = styled.div`
  height: 94px;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 94px;
  background: #fff;
  z-index: 1000;

  .logo .logo-name {
    line-height: 16px;
  }

  .btn-menu {
    width: 25px;
    height: 25px;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #202020;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        margin: 6px 0px;
      }
    }
  }

  .btn-menu.active {
    span {
      :nth-child(1) {
        transform: rotate(225deg);
      }

      :nth-child(2) {
        opacity: 0;
        transform: rotate(225deg);
      }

      :nth-child(3) {
        transform: rotate(315deg);
        margin-top: -16px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    width: 328px;
    height: 100vh;
    border-right: 1px solid #efefef;
    overflow: hidden;

    :hover {
      overflow-y: scroll;
      padding-right: 1.2rem !important;
    }

    .logo img {
      width: 63px;
      height: 63px;
    }
  }
`;

export const Menu = styled.div<IMenu>`
  position: absolute;
  top: 94px;
  left: ${(props) => (props.active ? 0 : -1000)}px;
  transition-duration: 0.3s;
  width: 100%;
  min-height: calc(100vh - 94px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    overflow: auto;

    .item-menu {
      color: #5c5c5c;
      border-radius: 12px;
      background: transparent;
      transition-duration: 0.3s;

      svg {
        width: 20px;
        height: 20px;
        color: #5c5c5c;
        transition-duration: 0.3s;
      }

      svg.gallery-icon {
        path {
          color: #5c5c5c;
          transition-duration: 0.3s;
        }
      }

      span {
        width: calc(100% - 36px);
        -webkit-text-fill-color: #5c5c5c;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: transparent;
        transition-duration: 0.3s;
      }
    }

    .item-menu:hover,
    .item-menu.active {
      color: #ad6211;
      background: #f4e9dd;

      svg {
        color: #ad6211;
      }

      svg.gallery-icon {
        path {
          stroke: #ad6211;
        }
      }

      span {
        -webkit-text-fill-color: #ad6211;
        -webkit-text-stroke-color: #ad6211;
      }
    }

    .box-user {
      width: calc(100% - 5px);
      border-radius: 20px;
      background: #ebebeb;
    }
  }

  .notification {
    width: 10px !important;
    height: 10px;
    border-radius: 50%;
    background-color: #be7e38;
  }

  @media screen and (min-width: 992px) {
    position: unset;
    min-height: unset;

    > div {
      overflow: hidden;
      min-height: calc(100vh - 187px) !important;
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-image: url(${(props) => props.src});
  background-color: #224b8f;
  background-position: center;
  background-size: cover;
`;
