import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  min-height: 100vh;

  .logo-name {
    line-height: 14px;
  }

  > div {
    min-height: 100vh;

    > div {
      min-height: 90vh;
    }
  }

  .banner {
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    border-radius: 16px 0 0 16px;
  }
`;
