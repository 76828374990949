import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IThumbnail {
  src?: string;
}

export const Container = styled.div``;

export const News = styled.div`
  .box {
    border-radius: 17px;
    background: #f2f2f2;
    display: block;
    overflow: hidden;
    transition-duration: 0.3s;

    .btn-edit {
      background-color: #cdc7c7;

      :hover {
        background-color: ${darken(0.03, '#cdc7c7')};
      }
    }

    :hover {
      background-color: ${darken(0.03, '#f2f2f2')};
    }
  }
`;

export const Thumbnail = styled.div<IThumbnail>`
  ${(props) => {
    if (props.src) {
      return css`
        background-image: url(${props.src});
      `;
    }

    return css``;
  }}
  background-color: #BE7E3855;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 97px;
  width: 100%;

  @media screen and (min-width: 992px) {
    background-position: center;
    height: 140px;
  }
`;
