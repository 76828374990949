import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { TbLogout } from 'react-icons/tb';

import { useAuth } from '~/hooks/Auth';

import { WhiteSpace, Container, Menu } from './styles';
import Icons from '../Icons';

import Logo from '~/assets/logos/Logo';

const socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/chat`);

const SideNavbar: React.FC = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [active, setActive] = useState(false);
  const [connectionEstablished, setConnectionEstablished] = useState(false);
  const [newMessage, setNewMessage] = useState(false);

  useEffect(() => {
    setActive(false);
    if (location.pathname.includes('conversas')) {
      setNewMessage(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'auto';
  }, [active]);

  useEffect(() => {
    if (!connectionEstablished) {
      socket.onopen = () => {
        const data = {
          user_id: 1,
          chat_id: user.id,
        };

        socket.send(JSON.stringify(data));
        setConnectionEstablished(true);
      };

      socket.onerror = () => {
        // console.log('Não foi possivel conectar no chat');
      };
    }
  }, [connectionEstablished, user.id]);

  useEffect(() => {
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.chat_id && !location.pathname.includes('conversas')) {
        setNewMessage(true);
      }
    };
  }, [location.pathname]);

  return (
    <>
      <WhiteSpace className="d-block d-lg-none" />
      <Container className="px-lg-4 py-lg-5 d-flex flex-column">
        <div className="mb-lg-5 p-4 p-lg-0 d-flex align-items-center justify-content-between">
          <Link
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="d-flex align-items-end justify-content-center logo"
          >
            <Logo color="#202020" className="me-2" />
            <span className="text-start d-block fs-6 fs-lg-5 fw-light mb-0 logo-name">
              Prime Odontologia
            </span>
          </Link>
          <button
            type="button"
            className={`bg-transparent d-lg-none border-0 mx-3 btn-menu ${
              active ? 'active' : ''
            }`}
            onClick={() => setActive(!active)}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <Menu active={active} className="p-3 p-lg-0">
          <div className="d-flex flex-column justify-content-between">
            <div>
              {user.type.types_menus.map((userTypeMenu) => (
                <NavLink
                  key={userTypeMenu.menu.id}
                  to={`${process.env.PUBLIC_URL}/${userTypeMenu.menu.link}`}
                  className="d-flex align-items-center p-3 mb-4 item-menu"
                  activeClassName="active"
                >
                  <Icons title={userTypeMenu.menu.icon} />{' '}
                  <span className="d-block ms-3">
                    {userTypeMenu.menu.title}
                  </span>
                  {userTypeMenu.menu.link.includes('conversas') &&
                    newMessage && <span className="notification" />}
                </NavLink>
              ))}
            </div>
            <button
              type="button"
              className="btn text-start d-flex align-items-center p-3 item-menu w-100"
              onClick={() => signOut()}
            >
              <TbLogout size={20} className="me-3" /> <span>Sair</span>
            </button>
          </div>
        </Menu>
      </Container>
    </>
  );
};

export default SideNavbar;
