import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RxPlus } from 'react-icons/rx';
import { useLocation } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import { IoCloseOutline } from 'react-icons/io5';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal } from './styles';
import Table, { IColumn } from '~/components/Table';
import Input from '~/components/Input';
import Toast from '~/utils/toast';
import Select, { IOption } from '~/components/Select';

interface IType {
  id: number;
  type: string;
}

interface IEmployeeData {
  id: number;
  name: string;
  email: string;
  type: IType;
}

interface IEmployee {
  id: number;
  name: string;
  email: string;
  type: string;
}

interface IEmployeeResponse {
  data: IEmployeeData[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IFormData {
  name: string;
  email: string;
  type: string;
  password: string;
}

const Settings: React.FC = () => {
  const addFormRef = useRef<FormHandles>(null);
  const updateFormRef = useRef<FormHandles>(null);
  const location = useLocation();
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState({} as IEmployee);
  const [types, setTypes] = useState<IOption[]>([]);
  const [showAutomaticMessages, setShowAutomaticMessages] = useState(false);

  useEffect(() => {
    api.get<IType[]>('users-types').then((response) => {
      const data = response.data.map<IOption>((type) => ({
        id: type.id,
        value: type.type,
        selected: false,
        notSelectable: false,
      }));

      setTypes(data);
    });
  }, []);

  const handleLoadEmployees = useCallback(
    async (pageData: number, searchData = '') => {
      const response = await api.get<IEmployeeResponse>('users', {
        params: {
          page: pageData,
          search: searchData,
        },
      });

      const data = response.data.data.map<IEmployee>((employee) => ({
        id: employee.id,
        name: employee.name,
        email: employee.email,
        type: employee.type.type,
      }));

      setEmployees(data);
      setTableData({
        from: response.data.from,
        to: response.data.to,
        total: response.data.total,
        current_page: pageData,
      });
    },
    []
  );

  useEffect(() => {
    handleLoadEmployees(1);
  }, [handleLoadEmployees, location.pathname]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      handleLoadEmployees(1, value);
      setSearch(value);
    },
    [handleLoadEmployees]
  );

  const handleClickUpdate = useCallback((employee: IEmployee) => {
    setEmployeeSelected(employee);
    setTypes((state) =>
      state.map((type) => ({
        ...type,
        selected: type.value === employee.type,
      }))
    );
    setShowUpdate(true);
  }, []);

  const handleClickDelete = useCallback(
    (employee: IEmployee) => {
      Swal.fire({
        title: `Deseja deletar o funcionário: ${employee.name}?`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#023A83',
        cancelButtonColor: '#777777',
        cancelButtonText: 'Não',
        reverseButtons: true,
        iconColor: '#023A83',
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`users/${employee.id}`);

            const newEmployees = employees.filter(
              (employeeData) => employeeData.id !== employee.id
            );

            setEmployees(newEmployees);

            Toast.fire({
              icon: 'success',
              title: 'Funcionário deletado!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [employees]
  );

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
        cell: (_: IEmployee, index) => (index + 1).toString().padStart(2, '0'),
      },
      {
        name: 'Tipo',
        selector: 'type',
      },
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: '',
        selector: 'id',
        cell: (row: IEmployee) => (
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="border-0 bg-transparent me-2"
              onClick={() => handleClickUpdate(row)}
            >
              <MdEdit size={24} color="#777777" />
            </button>
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={() => handleClickDelete(row)}
            >
              <MdDelete size={24} color="#FF1A50" />
            </button>
          </div>
        ),
      },
    ],
    [handleClickDelete, handleClickUpdate]
  );

  const handleChangePage = useCallback((pageData: number) => {
    setPage(pageData);
  }, []);

  const handleClose = useCallback(() => {
    setShowAutomaticMessages(false);
  }, []);

  const handleSubmitAdd = useCallback(
    async (data: IFormData) => {
      try {
        addFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string().email().required('O e-mail é obrigatório'),
          type: Yup.string().required('O tipo é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          user_type_id: parseInt(data.type, 10),
          name: data.name,
          email: data.email,
          password: data.password,
        };

        await api.post('users', formData);

        handleLoadEmployees(page, search);

        handleClose();

        Toast.fire({
          icon: 'success',
          title: 'Funcionário salvo!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          addFormRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [handleClose, handleLoadEmployees, page, search]
  );

  const handleSubmitUpdate = useCallback(
    async (data: IFormData) => {
      try {
        updateFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string().email().required('O e-mail é obrigatório'),
          type: Yup.string().required('O tipo é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          user_type_id: parseInt(data.type, 10),
          name: data.name,
          email: data.email,
          password: data.password,
        };

        await api.put(`users/${employeeSelected.id}`, formData);

        const newEmployees = employees.slice();
        const employeeIndex = newEmployees.findIndex(
          (employee) => employee.id === employeeSelected.id
        );

        if (employeeIndex >= 0) {
          const typeData = types.find(
            (type) => type.id === parseInt(data.type, 10)
          );
          newEmployees[employeeIndex].name = data.name;
          newEmployees[employeeIndex].email = data.email;
          if (typeData) {
            newEmployees[employeeIndex].type = typeData.value;
          }
        }

        setEmployees(newEmployees);

        handleClose();

        Toast.fire({
          icon: 'success',
          title: 'Funcionário editado!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          updateFormRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [employeeSelected.id, employees, handleClose, types]
  );

  const handleClickAutomaticMessages = useCallback(() => {
    setShowAutomaticMessages(true);
  }, []);

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-5">
            <h1>Configurações</h1>
          </div>
          <div className="col-12">
            <div className="box p-4">
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    onClick={handleClickAutomaticMessages}
                  >
                    Mensagens automáticas
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showAutomaticMessages}
        centered
        onHide={handleClose}
        size="lg"
      >
        <Form ref={addFormRef} onSubmit={handleSubmitAdd} className="p-3">
          <Modal.Header className="border-0">
            <h3>Mensagens automáticas:</h3>
            <button
              type="button"
              onClick={handleClose}
              className="border-0 bg-transparent close-btn"
            >
              <IoCloseOutline size={38} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
            <label className="w-100 mb-3">
              <span className="d-block mb-2 fw-medium">Nome</span>
              <Input name="name" placeholder="Digite aqui" />
            </label>
            <label className="w-100 mb-3">
              <span className="d-block mb-2 fw-medium">E-mail</span>
              <Input type="email" name="email" placeholder="Digite aqui" />
            </label>
            <label className="w-100 mb-3">
              <span className="d-block mb-2 fw-medium">Tipo</span>
              <Select name="type" placeholder="Digite aqui" options={types} />
            </label>
            <label className="w-100">
              <span className="d-block mb-2 fw-medium">Senha</span>
              <Input
                type="password"
                name="password"
                placeholder="Digite aqui"
              />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showUpdate} centered onHide={handleClose}>
        <Form
          ref={updateFormRef}
          onSubmit={handleSubmitUpdate}
          initialData={employeeSelected}
          className="p-3"
        >
          <Modal.Header className="border-0">
            <h3>Editar funcionário:</h3>
            <button
              type="button"
              onClick={handleClose}
              className="border-0 bg-transparent close-btn"
            >
              <IoCloseOutline size={38} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
            <label className="w-100 mb-3">
              <span className="d-block mb-2 fw-medium">Nome</span>
              <Input name="name" placeholder="Digite aqui" />
            </label>
            <label className="w-100 mb-3">
              <span className="d-block mb-2 fw-medium">E-mail</span>
              <Input type="email" name="email" placeholder="Digite aqui" />
            </label>
            <label className="w-100 mb-3">
              <span className="d-block mb-2 fw-medium">Tipo</span>
              <Select name="type" placeholder="Digite aqui" options={types} />
            </label>
            <label className="w-100">
              <span className="d-block mb-2 fw-medium">Senha</span>
              <Input
                type="password"
                name="password"
                placeholder="Digite aqui"
              />
            </label>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Settings;
