import { darken } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  .btn-blue {
    background-color: #0075ff;
    color: #fff;
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.1, '#0075FF')};
    }
  }

  .box {
    border-radius: 23px;
    background: #fff;

    .table-user-type {
      > div {
        .content {
          .tb-header,
          .tb-body {
            width: 100%;

            .tb-row {
              width: 100%;

              > button:nth-child(1),
              > div:nth-child(1) {
                width: 100px;
              }

              > button:nth-child(2),
              > div:nth-child(2) {
                width: calc(100% - 190px);
              }

              > button:nth-child(3),
              > div:nth-child(3) {
                width: 90px;
              }
            }
          }
        }
      }
    }

    .input {
      background: #f9f9f9;
      border-radius: 50px;
      border: 1px solid #e4e4e4;
      padding: 5px 15px;
      width: 400px;
      display: flex;
      align-items: center;
      height: 46px;
      color: #777777;

      input {
        width: calc(100% - 32px);

        ::placeholder {
          color: #a3a3a3;
        }
      }
    }

    .btn-filter {
      border-radius: 50%;
      border: 1px solid #e4e4e4;
      background: #f9f9f9;
      padding: 10px;
    }

    .box-gray {
      border-radius: 16px;
      background: #f9f9f9;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 23px;
    background: #fff;
    border: none;
    position: relative;

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .menus {
      justify-content: flex-start;
      flex-wrap: wrap;

      label {
        margin: 5px;
        padding: 10px;
        border-radius: 16px;
        border: 1px solid #dbe1eb;
        background: #fff;

        + label {
          margin-left: 5px;
        }
      }
    }
  }
`;
