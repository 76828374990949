import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RxPlus } from 'react-icons/rx';
import { TbEdit } from 'react-icons/tb';

import { Container, News, Thumbnail } from './styles';

import logo from '~/assets/logos/logo-short.svg';
import api from '~/services/api';

interface INotice {
  id: number;
  title: string;
  published: boolean;
  slug: string;
  thumbnail: {
    archive_url: string;
  };
}

const Blog: React.FC = () => {
  const [notices, setNotices] = useState<INotice[]>([]);

  const handleLoadNotices = useCallback(async (page) => {
    const response = await api.get('notices', {
      params: {
        page,
        admin: true,
      },
    });
    if (page === 1) {
      setNotices(response.data.data);
    } else {
      setNotices((state) => [...state, ...response.data.data]);
    }
  }, []);

  useEffect(() => {
    handleLoadNotices(1);
  }, [handleLoadNotices]);

  return (
    <Container className="py-5">
      <News className="container">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between my-5">
            <h1 className="h2">Blog</h1>
            <Link
              to={`${process.env.PUBLIC_URL}/blog/novo`}
              className="btn btn-primary rounded-pill px-5 py-2 d-flex align-items-center"
            >
              <RxPlus size={20} color="#fff" className="me-2" /> Criar novo
              artigo
            </Link>
          </div>
          {notices.map((notice) => (
            <div key={notice.id} className="col-6 col-lg-4 mb-4">
              <div className="box">
                <div className="p-4">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <img
                      src={logo}
                      alt="Ícone da logo COI Prime Odontologia"
                      className="mb-3"
                    />
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog/${notice.slug}`}
                      className="btn btn-edit rounded-pill px-4 py-1 d-flex align-items-center"
                    >
                      Editar{' '}
                      <TbEdit size={20} color="#202020" className="ms-2" />
                    </Link>
                  </div>
                  <h3 className="fs-6 fs-xxl-5 fw-medium">{notice.title}</h3>
                </div>
                <Thumbnail src={notice.thumbnail?.archive_url} />
              </div>
            </div>
          ))}
        </div>
      </News>
    </Container>
  );
};

export default Blog;
