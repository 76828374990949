import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import { RxPlus } from 'react-icons/rx';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container, Preview, Characters } from './styles';
import Select, { IOption } from '~/components/Select';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputEditor from '~/components/InputEditor';
import Toast from '~/utils/toast';

interface IUser {
  id: number;
  name: string;
  avatar: {
    avatar_url: string;
  };
}

interface IFormData {
  author: string;
  title: string;
  description: string;
  body: string;
}

const New: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [banner, setBanner] = useState<File | undefined>();
  const [bannerUrl, setBannerUrl] = useState('');
  const [published, setPublished] = useState('');
  const [options, setOptions] = useState<IOption[]>([]);
  const [titleQty, setTitleQty] = useState(0);
  const [titleError, setTitleError] = useState('');
  const [descriptionQty, setDescriptionQty] = useState(0);
  const [descriptionError, setDescriptionError] = useState('');

  useEffect(() => {
    api.get<IUser[]>('users').then((response) => {
      const data = response.data.map<IOption>((user) => {
        return {
          id: user.id,
          value: user.name,
          icon: user.avatar.avatar_url,
          selected: false,
        };
      });
      setOptions(data);
    });
  }, []);

  useEffect(() => {
    const titleElement = document.getElementById('title');
    if (titleElement) {
      titleElement.style.height = 'auto';
      titleElement.style.height = `${titleElement.scrollHeight}px`;
    }
  }, [titleQty]);

  useEffect(() => {
    const descriptionElement = document.getElementById('description');
    if (descriptionElement) {
      descriptionElement.style.height = 'auto';
      descriptionElement.style.height = `${descriptionElement.scrollHeight}px`;
    }
  }, [descriptionQty]);

  const handleChangeBanner = useCallback((e) => {
    if (e.target.files[0]) {
      setBanner(e.target.files[0]);
      setBannerUrl(URL.createObjectURL(e.target.files[0]));
    } else {
      setBanner(undefined);
    }
  }, []);

  const handleChangeTitle = useCallback((e) => {
    formRef.current?.setErrors({});

    const qty = e.target.value.length;
    setTitleQty(qty);
    if (qty < 30) {
      setTitleError('Quantidade de caracteres insulficiente para um bom SEO');
    }
    if (qty > 65) {
      setTitleError('Quantidade de caracteres ultrapassadas para um bom SEO');
    }
    if (qty === 0 || (qty > 30 && qty <= 65)) {
      setTitleError('');
    }
  }, []);

  const handleChangeDescription = useCallback((e) => {
    formRef.current?.setErrors({});

    const qty = e.target.value.length;
    setDescriptionQty(qty);
    if (qty < 120) {
      setDescriptionError(
        'Quantidade de caracteres insulficiente para um bom SEO'
      );
    }
    if (qty > 320) {
      setDescriptionError(
        'Quantidade de caracteres ultrapassadas para um bom SEO'
      );
    }

    if (qty === 0 || (qty >= 120 && qty <= 320)) {
      setDescriptionError('');
    }
  }, []);

  const handleClickSubmit = useCallback((willPublished) => {
    setPublished(willPublished);
    setTimeout(() => {
      formRef.current?.submitForm();
    }, 100);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          author: Yup.string().required('O autor é obrigatório'),
          title: Yup.string().required('O título é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          body: Yup.string().required('O conteudo é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          author: data.author,
          title: data.title,
          description: data.description,
          body: data.body,
          published,
        };

        const response = await api.post('notices', formData);

        const bannerFormData = new FormData();
        bannerFormData.append('notice_id', response.data.id);
        bannerFormData.append('banner', banner as File);

        await api.post('archives', bannerFormData);

        Toast.fire({
          icon: 'success',
          title: 'Artigo criado com sucesso!',
        });

        history.push(`${process.env.PUBLIC_URL}/blog`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [banner, history, published]
  );

  return (
    <Container className="py-5">
      <div className="container">
        <Form ref={formRef} onSubmit={handleSubmit} className="row">
          <div className="col-12 mb-5">
            <div className="d-flex align-items-center">
              <Link
                to={`${process.env.PUBLIC_URL}/blog`}
                className="d-block me-1"
              >
                <RiArrowLeftSLine size={44} color="#707070" />
              </Link>
              <h1 className="h2 mb-0">Criar novo artigo</h1>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box p-5 h-100">
              <div>
                <label className="cursor-pointer d-block w-100">
                  <div className="d-flex align-items-center">
                    <div className="plus-icon">
                      <RxPlus size={16} color="#fff" />
                    </div>
                    <p className="mb-0 fw-medium ms-2">Imagem</p>
                  </div>
                  <Input
                    type="file"
                    name="banner"
                    className="d-none"
                    onChange={handleChangeBanner}
                  />
                  {bannerUrl ? (
                    <Preview src={bannerUrl} className="mt-3" />
                  ) : (
                    <hr className="mt-5" />
                  )}
                </label>
              </div>
              <div>
                <label className="d-block w-100 mt-4 mb-3">
                  <span className="fw-medium mb-2 d-block">Autor</span>
                  <Select
                    name="author"
                    options={options}
                    className="input"
                    placeholder="Selecione"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="box p-5">
              <div className="d-flex align-items-start">
                <div className="w-100">
                  <Textarea
                    name="title"
                    placeholder="Digite um título"
                    className="d-block w-100 border-0 bg-transparent title mb-3"
                    onChange={handleChangeTitle}
                    id="title"
                    rows={1}
                    error={titleError}
                  />
                </div>
                <Characters
                  qty={titleQty}
                  min={30}
                  max={65}
                  className="mb-0 mt-3 px-3 py-2"
                >
                  {titleQty}/65
                </Characters>
              </div>
              <div className="d-flex align-items-start">
                <div className="w-100">
                  <Textarea
                    name="description"
                    placeholder="Descrição do artigo"
                    className="d-block w-100 border-0 bg-transparent description"
                    rows={3}
                    onChange={handleChangeDescription}
                    id="description"
                    error={descriptionError}
                  />
                </div>
                <Characters
                  qty={descriptionQty}
                  min={120}
                  max={320}
                  className="mb-0 px-3 py-2"
                >
                  {descriptionQty}/320
                </Characters>
              </div>
              <div className="mt-4">
                <InputEditor
                  name="body"
                  uploadUrl={`${process.env.REACT_APP_API_URL}/archives`}
                />
              </div>
              <div className="d-flex justify-content-end mt-5">
                <button
                  type="button"
                  className="btn btn-secondary px-5 py-2 rounded-pill me-2"
                  onClick={() => handleClickSubmit(false)}
                >
                  Salvar rascunho
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-5 py-2 rounded-pill"
                  onClick={() => handleClickSubmit(true)}
                >
                  Salvar e publicar
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default New;
