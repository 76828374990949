import React from 'react';

import { Container, Banner } from './styles';

import logo from '~/assets/logos/logo-short.svg';

const Dashboard: React.FC = () => {
  return (
    <Container className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-5 pb-5">
            <Banner className="p-5">
              <div className="py-5">
                <img src={logo} alt="Logo" />
                <h1 className="h2 mt-3">Olá, Boa tarde</h1>
                <p>Bem-vindo (a) à plataforma</p>
              </div>
            </Banner>
          </div>
          <div className="col-lg-4">
            <div className="box p-4">
              <div className="detail bg-yellow" />
              <p className="h1 fw-semibold">02</p>
              <p>
                Quantidade de <br />
                <b>Artigos no blog</b>
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box p-4">
              <div className="detail bg-brown" />
              <p className="h1 fw-semibold">02</p>
              <p>
                Minha <br />
                <b>equipe</b>
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="box p-4">
              <div className="detail bg-orange" />
              <p className="h1 fw-semibold">02</p>
              <p>
                Quantidade de <br />
                <b>Depoimentos</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
