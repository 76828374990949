/* eslint-disable no-async-promise-executor */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import api from '~/services/api';

import { Container } from './styles';
import FlowModal from './FlowModal';
import Options from './Options';

export interface IOption {
  id: number;
  content: string;
  selector: number;
  status: 'new' | 'old';
  flow: IFlow;
}

export interface IFlow {
  id: number;
  name: string;
  message: string;
  status: 'new' | 'old';
  options: IOption[];
  user_type_id?: number;
  action: string;
}

interface IFlowResponse {
  id: number;
  name: string;
  message: string;
  options: IOption[];
  user_type_id?: number;
  action: string;
}

const Messages: React.FC = () => {
  const location = useLocation();
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [oldFlows, setOldFlows] = useState<IFlow[]>([]);
  const [flowSelected, setFlowSelected] = useState({} as IFlow);
  const [show, setShow] = useState(false);
  const [optionIdSelcted, setOptionIdSelcted] = useState<number | undefined>(
    undefined
  );

  const handleLoadFlows = useCallback(async () => {
    const response = await api.get<IFlowResponse[]>('flows');

    const data = response.data.map<IFlow>((flow) => {
      return {
        id: flow.id,
        name: flow.name,
        message: flow.message,
        status: 'old',
        options: flow.options.map<IOption>((option) => ({
          ...option,
          status: 'old',
        })),
        user_type_id: flow.user_type_id,
        action: flow.action,
      };
    });

    setFlows(data);
    setOldFlows(data);
  }, []);

  useEffect(() => {
    handleLoadFlows();
  }, [handleLoadFlows, location.pathname]);

  const handleClose = useCallback(() => {
    setShow(false);
    setFlowSelected({} as IFlow);
    setOptionIdSelcted(undefined);
  }, []);

  const handleSelectFlow = useCallback((flow, optionId?: number) => {
    setFlowSelected(flow);
    setOptionIdSelcted(optionId);
    setShow(true);
  }, []);

  const updateFlowRecursively = useCallback(
    (oldFlow: IFlow, newFlow: IFlow): IFlow => {
      if (oldFlow.id === newFlow.id) {
        return { ...newFlow };
      }
      return {
        ...oldFlow,
        options: oldFlow.options.map((option) => {
          return {
            ...option,
            flow: updateFlowRecursively(option.flow, newFlow),
          };
        }),
      };
    },
    []
  );

  const getFlow = useCallback(async (options: IOption[], flow: IFlow) => {
    return new Promise<IFlow | undefined>(async (resolve) => {
      for (const option of options) {
        if (option.flow.id === flow.id) {
          resolve(option.flow);
          return; // Para garantir que a execução pare aqui
        }
        if (option.flow.options.length > 0) {
          const flowData = await getFlow(option.flow.options, flow);
          if (flowData) {
            resolve(flowData);
            return; // Para garantir que a execução pare aqui
          }
        }
      }

      // Se nenhum fluxo foi encontrado, resolve com undefined
      resolve(undefined);
    });
  }, []);

  const handleSaveFlow = useCallback(
    async (flow: IFlow) => {
      const newFlow = flow;

      const formData = {
        option_id: optionIdSelcted,
        name: newFlow.name,
        message: newFlow.message,
        action: newFlow.action,
        user_type_id: newFlow.user_type_id,
      };

      const options = oldFlows.map<IOption>((oldFlow, index) => ({
        id: new Date().getTime(),
        content: oldFlow.name,
        flow: oldFlow,
        selector: index + 1,
        status: 'old',
      }));

      const oldFlowData = await getFlow(options, newFlow);

      const optionsDeleted: IOption[] = [];
      if (oldFlowData) {
        const optionsData = oldFlowData.options.filter(
          (oldOption) =>
            !flow.options.some((option) => option.id === oldOption.id)
        );

        optionsDeleted.push(...optionsData);
      }

      let flowId = newFlow.id;
      if (newFlow.status === 'new') {
        const response = await api.post('flows', formData);
        flowId = response.data.id;
      } else {
        await api.put(`flows/${flowId}`, formData);
      }

      if (newFlow.options && newFlow.options.length > 0) {
        let index = 0;
        for (const option of newFlow.options) {
          const formDataOption = {
            flow_id: flowId,
            content: option.content,
            selector: option.selector,
          };

          let optionId = option.id;
          let optionFlowId = option.flow.id;
          if (option.status === 'new') {
            const response = await api.post('options', formDataOption);
            const flowFormData = {
              option_id: response.data.id,
              name: option.content,
              action: 'options',
            };
            const responseFlow = await api.post('flows', flowFormData);
            optionId = response.data.id;
            optionFlowId = responseFlow.data.id;
          } else {
            const flowFormData = {
              option_id: optionId,
              name: option.content,
              message: option.flow.message,
            };

            await api.put(`flows/${option.flow.id}`, flowFormData);
            await api.put(`options/${optionId}`, formDataOption);
          }

          newFlow.options[index].id = optionId;
          newFlow.options[index].status = 'old';
          newFlow.options[index].flow.id = optionFlowId;
          newFlow.options[index].flow.status = 'old';

          index += 1;
        }
      }

      if (optionsDeleted.length > 0) {
        for (const optionDeleted of optionsDeleted) {
          await api.delete(`flows/${optionDeleted.flow.id}`);
          await api.delete(`options/${optionDeleted.id}`);
        }
      }

      newFlow.id = flowId;
      newFlow.status = 'old';

      const newFlows = flows.map((flowData) => {
        if (flowData) {
          if (flowData.id === newFlow.id) {
            return {
              ...newFlow,
            };
          }

          if (flowData.options && flowData.options.length > 0) {
            return {
              ...flowData,
              options: flowData.options.map((option) => {
                if (option.flow.id === newFlow.id) {
                  return {
                    ...option,
                    flow: newFlow,
                  };
                }
                return {
                  ...option,
                  flow: updateFlowRecursively(option.flow, newFlow),
                };
              }),
            };
          }
        }
        return flowData;
      });

      setFlows(newFlows);
      setOldFlows(newFlows);
      setFlowSelected({} as IFlow);
      setShow(false);
      setOptionIdSelcted(undefined);
    },
    [flows, getFlow, oldFlows, optionIdSelcted, updateFlowRecursively]
  );

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-5">
            <h1 className="h2">Configurações - Mensagens automáticas</h1>
          </div>
          <div className="col-12">
            {flows.map((flow) => (
              <div key={flow.id} className="box p-4">
                <div>
                  <button
                    type="button"
                    className="flow"
                    onClick={() => handleSelectFlow(flow)}
                  >
                    {flow.name}
                  </button>
                  {flow.options.length > 0 && (
                    <Options
                      options={flow.options}
                      handleSelectFlow={handleSelectFlow}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {Object.keys(flowSelected).length > 0 && (
        <FlowModal
          flow={flowSelected}
          show={show}
          onHide={handleClose}
          onSave={handleSaveFlow}
        />
      )}
    </Container>
  );
};

export default Messages;
